.fancybox-content {
  background-color:#FFF;
  background-color:none;
  border-radius:26px;
  box-shadow: 0px 12px 20px #999;
}
.fancybox-is-open {

  .fancybox-bg {
    opacity: .4;
    transition-timing-function: cubic-bezier(.22, .61, .36, 1);

  }
}
#body {


  &.is-active-menu-open {
    #main , #footer {
      display:none;
    }
     .header {

    .item-logo {
      width:75px;
    }
    h2 {
      font-size: 1em;
    }
    p {
        display:block;
    }
  }

 

    .header__menu ,
    .c-header
    { 
      @extend .e-fade-in-left;
      display:block;
    }
  }


}
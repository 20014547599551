#map {
  height: 820px;
  width:100%;
  margin:0 auto 2em;
  display: block;
  flex:1;
}

.custom-info-window {
  padding: 0px;
  max-width:110px;
  h3 {
    color:$branding_blue;
    font-size: 1em;
  }
  p {
    margin:0;
    color:#000;
  }
}

#mapa-text {
  color:#000;
}
#mapa-text ul {
  list-style:none;
}
#mapa svg {
  max-width: 30px;
}

@media screen and (max-width: 990px) {
 #map {
   flex:initial;
   order:1;
 }
}
@media screen and (max-width: 500px) {
 #map {
   height: 600px;
 }
}
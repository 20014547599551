/* loading spinner */
.e-spinner {
  span {
    
  }
  width: 40px;
  height: 40px;
  margin: auto;
  border-radius: 50%;
  border-top: 2px solid #eee;
  border-right: 2px solid #eee;
  border-bottom: 2px solid #eee;
  border-left: 2px solid #ccc;
  -webkit-animation: e-animation-spin 0.8s infinite linear;
  animation: e-animation-spin 0.8s infinite linear;
}
@-webkit-keyframes e-animation-spin{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);-ms-transform:rotate(359deg);transform:rotate(359deg)}}
@keyframes e-animation-spin{0%{-webkit-transform:rotate(0deg);-ms-transform:rotate(0deg);transform:rotate(0deg)}100%{-webkit-transform:rotate(359deg);-ms-transform:rotate(359deg);transform:rotate(359deg)}}

.e-spinner + i {
  @extend .absolute, .tc , .fs-normal;
  width:40px;
  height:40px;
  line-height: 40px;
}
// General border box, que no suporta normalize.css

// https://css-tricks.com/box-sizing/ 
// Millors practiques per als 'pseudo elements'
*, *:before, *:after {
	-webkit-box-sizing: inherit;
	-moz-box-sizing: inherit;
	box-sizing: inherit;
}

html {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;

  font-family: sans-serif; /* 2 */
  line-height: 1.15; /* 3 */
  -webkit-text-size-adjust: 100%; /* 4 */
  -ms-text-size-adjust: 100%; /* 4 */
  -ms-overflow-style: scrollbar; /* 5 */
  -webkit-tap-highlight-color: transparent; /* 6 */
  
}
/* IE10+ doesn't honor `<meta name="viewport">` in some cases. */
@-ms-viewport {
  width: device-width;
}

body {
  // font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
  font-family: $Poppins; 
  font-weight: normal;
  line-height: 1.5;
  font-weight: 400;
  font-size:16px;
  color:$color_primary;
}

a[href^="tel"] {
  // color: inherit; /* Inherit text color of parent element. */
  text-decoration: none; /* Remove underline. */
  /* Additional css `propery: value;` pairs here */
}

b , strong {
	font-weight: 700;
} 

p {
	line-height: 1.5em;
  margin-top: 0;
  margin-bottom: 1rem;
}
h1, h2, h3, h4, h5, h6 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  line-height: 1.2;

}
h1 {
  font-size:2.5em;
}
h2 {
  font-size:2em;
}
h3 {
  font-size: 1.75em;
}
h4 {
  font-size: 1.5em;
}
h5 {
  font-size: 1.25em;
}
h6 {
  font-size: 1em;
}
h3, h4, h5, h6 {
  font-weight:600;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}
ol, ul {
  padding-left: 2rem;
}
ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

ul , ol {
  padding-left:20px;
  margin-bottom: 1rem;
  li {
    margin-bottom: .25em;
  }
}
ol ol, ol ul, ul ol, ul ul {
  margin-bottom: 0;
}
dl, ol, ul {
  margin-top: 0;
  margin-bottom: 1rem;
}

a {
	color:$color_primary;
}

// no suporta normalize.css, queda el defecte pel browser
figure {
	margin:0;
	padding:0;
}

img {
	max-width: 100%;
	height:auto;
} 


video , iframe  {
  display:block;
}


select {
  /* Remove IE arrow */
  &::-ms-expand {
    display: none;
  }
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: 0;
  border: 0;
  box-shadow: none;
  word-wrap: normal;
  color:$branding_gray;
  
  /* Personalize */
  display: block;
  padding: 0.375rem 2.25rem 0.375rem 0.75rem;
  -moz-padding-start: calc(0.75rem - 3px);
  // line-height: 1.4;
  background-color: #fff;
  background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3e%3cpath fill='none' stroke='%23343a40' stroke-linecap='round' stroke-linejoin='round' stroke-width='2' d='m2 5 6 6 6-6'/%3e%3c/svg%3e");
  background-repeat: no-repeat;
  background-position: right 0.75rem center;
  // background-size: 16px 12px;

  background-image: url($path + "assets/svg/arrow-bottom-input2.svg");
  background-size: contain;
  background-position:right;
  cursor:pointer;

  border: 1px solid #ced4da;
  // border-radius: 0.375rem;
  border-radius: 0.2rem;
  transition: border-color .15s ease-in-out,box-shadow .15s ease-in-out;
  


  &:active {
    filter: brightness(90%);
  }
  &.is-active {
    background-image: url($path + "assets/svg/arrow-bottom-active2.svg");
  }
  &:focus {
    border-color: $branding_gray;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(darken($branding_gray,10),.15)
  }


  width:100%;
  border-radius:12px;
  padding:10px;
  height: 50px;
  line-height: 100%;
  padding: 5px 16px;
  border:1px solid;

  &:active {
    filter: brightness(90%);
  }
  &:focus {
    border-color: $branding_gray;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(darken($branding_gray,10),.15)
  }


}
input {
  border-radius:12px;
  padding:10px;
  height: 50px;
  line-height: 50px;
  padding: 5px 16px;
  &:active {
    filter: brightness(90%);
  }
  &:focus {
    border-color: $branding_gray;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(darken($branding_gray,10),.15)
  }
}
input[type="radio"] {
  @include reset;
  margin-right: 8px;
  width :15px;
  height:15px;
  border:2px solid #c9c9c9;
  border-radius:50%;
  &:checked {
    background-color:#c9c9c9;
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23000'/%3e%3c/svg%3e");
  }
  &:active {
    filter: brightness(90%);
  }
  &:focus {
    border-color: $branding_gray;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(darken($branding_gray,10),.15)
  }
}
input[type="checkbox"]{
  appearance: none;
  -webkit-appearance: none;
  margin-right: 8px;
  border:2px solid #c9c9c9;
  width :15px;
  height:15px;
  border-radius:2px;
  display: inline-block;
  padding:0;
  &:checked {
    background-color:$branding_black;
    background-image:url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23fff' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
  }
  &:active {
    filter: brightness(90%);
  }
  &:focus {
    border-color: $branding_gray;
    outline: 0;
    box-shadow: 0 0 0 .25rem rgba(darken($branding_gray,10),.25)
  }
}
// e-btn
.e-btn {


  padding: 0;
  border: 0;
  border-radius: 0;
  line-height: inherit;
  appearance: none;
  -webkit-appearance: none;

  // background-color:blue;
  // color:#FFF;
  text-decoration:none;
  padding:10px 30px;
  border-radius:0px;
  position:relative;
  border:0;
  cursor:pointer;
  box-sizing:border-box;
  transition:.2s;
  display:inline-block;
  border-radius:30px;
  // outline:0;
  // filter: drop-shadow(0px 3px 4px #333);
  &::before {
    box-shadow:0px 3px 4px #666;
    content:"";
    border-radius:30px;
    mix-blend-mode: multiply;
    position:absolute;
    bottom:0;
    left:0;
    right:0;
    top:0;
  }
  &::after {
    content:"";
    margin-left:.5em;
    transition:.2s;
    display:inline-block;
    vertical-align:middle;
    width:16px;
    height:16px;
    background:url($path + 'assets/svg/arrow-btn-right.svg') no-repeat center center;
  }
  &:hover {
    background-color:$branding_black;
    color:$branding_white;
    &::after {
      margin-left:1em;
    }
  }
  &--no-arrow {
    // background-color:#FFF;
    // color:#656974;
    &::after ,
    &::after 
    {
      display:none;
    }
  }
  &--transparent {
    background-color:transparent;
    color:#222;
    font-weight: 500;
    border:1px solid #666;
    box-sizing:border-box;
    display:inline-block;
  }
  &--brown {
    background-color:$branding_brown;
    color:#FFF;
    font-weight: 500;
    border:0;
    box-sizing:border-box;
    display:inline-block;
  }
  &--small-padding {
    // padding:4px 20px;
  }
  &--big-padding {
    padding-right:60px;
    padding-left:60px;
  }
  &--big {
    min-width: 220px;
  }

  
}
/*  Globals / Abstracts */
@import
"global/vars",
"global/mixins"
;
/* Vendors */
@import "assets/library/tachyons-sass-main/tachyons"; // https://github.com/tachyons-css/tachyons-sass merge on 5 Oct 2021 github.com
@import "node_modules/flexboxgrid/css/flexboxgrid"; //http://flexboxgrid.com/
@import "node_modules/flickity/css/flickity";
@import "node_modules/flickity-fade/flickity-fade";
@import "node_modules/aos/dist/aos";
@import "node_modules/@fancyapps/fancybox/dist/jquery.fancybox";
// Vendor (Modified)
@import
"vendor/v-flickity",
"vendor/v-fancybox"
;
/* Base */
@import
// @import "base/wp"; // Only WP
"base/fonts",
"base/theme"
;
// u- Utils
@import
"utils/u-ratio",
"utils/--dev"
;
// Partials
@import
"partials/body",
"partials/header",
"partials/main",
"partials/footer"
;
// State / State JS
@import
"state/s-general"
;
//Elements
@import
"elements/e-elements"
;
// Components
@import
"components/c-form",
"components/map",
"components/c-header",
"components/c-slider-home"
;

@media (max-width: 767px) {
  iframe {
    margin:0 auto !important;
    display:block;
    width: 95vw !important;
  }
}

.hero-banner {
  position: relative;
  width: 100%;
  height: 325px;
  background-image: url($path + 'assets/img/b-taxi.jpg');
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: center;
  align-items: center;
  @include mobile-xs {
    height:150px;
  }
}

.hero-banner__title {
  position: relative;
  z-index: 1;
  color: white;
  font-size: 3rem;
  text-align: center;
  font-weight: 600;
  text-shadow: rgba(0,0,0,.9) 0px 1px 5px;
  animation: slideInTitle 2s forwards;
  @include mobile-xs {
      font-size: 1.7rem !important;
  }

  .hero-banner__title.loaded {
    opacity: 1;
    transform: translateY(0);
  }


  @keyframes slideInTitle {
    from {
      transform: translateY(-20px);
      opacity:0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
  @include mobile-xs {
    font-size: 2.4rem;
  }
}

.hero-banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 0;
}







.header__hamburger {
  display:none;
  @include mobile-theme {
    display: block;
    background: none;
    border: none;
    cursor: pointer;
    padding: 0;
    width: 60px;
    height: 60px;
    position: relative;
    float:right;
    .bar {
      display: block;
      position: absolute;
      height: 5px;
      width: 45px;
      background-color: #0022FF;
      border-radius: 3px;
      transition: transform 0.3s ease-out;
    }

    .bar:first-child {
      top: 18px;
    }

    .bar:nth-child(2) {
      top: 28px;
    }

    .bar:last-child {
      top: 38px;
    }

    &.open .bar:first-child {
      transform: translateY(14px) rotate(45deg);
    }

    &.open .bar:nth-child(2) {
      opacity: 0;
    }

    &.open .bar:last-child {
      transform: translateY(-7px) rotate(-45deg);
    }
  }
}



#body {

  @media (min-width: $grid-mobile-theme) {

    display:flex;
    flex-wrap: wrap;
    #header {
      width:270px;
      height:100%;
    }
    #main {
      flex:1;
      width:calc(270px - 100% );
    }

  }


  #footer {
    width:100%;
  }
}










.c-form {
  display:flex;
  /* Estilo general del formulario */
  form {
    max-width: 600px;
    font-family: Arial, sans-serif;
    color: #333;
    background-color: #f7f7f7;
    padding: 20px;
    border-radius: 10px;
  }

  /* Estilo para las etiquetas del formulario */
  label {
    display: block;
    margin-bottom: 10px;
  }

  /* Estilo para los campos de entrada */
  input[type="text"],
  input[type="tel"],
  input[type="email"],
  textarea,
  select {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
    margin-bottom: 10px;
  }

  /* Estilo para el botón de enviar */
  input[type="submit"] {
    background-color: $branding_blue;
    color: #fff;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    line-height: 50%;
  }

  /* Estilo para el botón de enviar al pasar el cursor sobre él */
  input[type="submit"]:hover {
    background-color: $branding_black;
  }

  /* Estilo para el campo de aceptación de condiciones legales */
  input[type="checkbox"] {
    margin-right: 5px;
  }

  /* Estilo para las condiciones legales */
  label[for="condiciones"] {
    display: block;
    margin-bottom: 10px;
  }

  /* Estilo para el mensaje de confirmación */
  .confirmacion {
    background-color: #d4edda;
    color: #155724;
    padding: 10px;
    border-radius: 4px;
    margin-top: 20px;
    display: none;
  }


}
// E-CONTAINER - CONTAINER
.e-grid-container , 
.e-container,
.grid-container , 
.container 
{
  @include grid-container;
  .row {
    /*empty*/
  }
}
.e-grid-container-full , 
.e-container-full,
.grid-container-full , 
.container-full 
{
  /*empty*/
}
.container-mobile-theme-margin-right-none {
  @include grid-container;
  @include mobile-theme {
    max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right:0em;
  padding-left:1.5em;
  }
}
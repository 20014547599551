.c-slider-home__item {
  position:relative;
  img {
    object-fit:cover;
    object-position:bottom;
    display: block;
    min-height:80vh;
    display:block;
    width:100%;
  }
}
.c-slider-home__caption {
  position:absolute;
  top: 20%;
  transform:translateY(-20%);
  width:calc( 88% );
  left:6%;
  color:#FFF;
  z-index:2;
}

.c-slider-home {
  position:relative;

//https://codepen.io/cumesoft/pen/yLxZdRO
.element , .typed-cursor 
{
  font-size:calc(3.25em + 1vw);
  @include mobile-theme {
    font-size:calc(2em + 1vw);
  }
}
.element {
  position: relative;
  display: inline;
  text-shadow: rgba(0, 0, 0, 0.9) 0px 1px 5px;
}
.typed-cursor {
  position: relative;
  display: inline;
  text-shadow: rgba(0, 0, 0, 0.9) 0px 1px 5px;
  font-weight:500;
  margin-top: 9%;
  opacity: 1;
  animation: blink 0.5s infinite;
  -webkit-animation: blink 0.5s infinite;
  -moz-animation: blink 0.5s infinite;
}

@keyframes blink {
 0% { opacity: 1; }
 50% { opacity: 0; }
 100% { opacity: 1; }
}
@-webkit-keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 0; }
  100% { opacity: 1; }
}
@-moz-keyframes blink {
  0% { opacity: 1; }
  50% { opacity: 1; }
  100% { opacity: 1; }
}

}
.e-header-user {
  @include reset_list;
  background-color: #5672a7;
  border-radius: 38px;
  color:#FFF;
  a {
    color:#FFF;
    @extend .dim;
  }
  .item-total-units {
    text-align:center;
    display:inline-block;
    background-color:#FFF;
    color:#5672a7;
    border-radius:100%;
    line-height: 18px;
    height:18px;
    width :18px;
    font-size:.8rem;
    font-weight:500;

  }
  .item-figure-user {
    width:80px;
    img {
      display:block;

    }
    @include mobile-xs {
      width:50px;
    }
  }
  .e-header-user__container {
    padding: 15px 50px;
    @include mobile-xs {
      padding: 15px 10px;
    }
  }
  .e-header-user__grid {
    display:flex;
    justify-content: space-between;
  }
  .e-header-user__col {
    &--left {

    }
    &--right {
      margin:auto 0 10px auto;
      min-width:100px;
      @include mobile-xs {
        margin-bottom:0;
      }
    }
  }
}

.e-paragraf-theme {
  line-height: 1.75em;
  p {
    margin-bottom: 3em;
    line-height: 1.75em;
  }
}
.e-style-dot-ok {
  list-style: none !important;
  list-style-position: outside !important;
  position:relative;
  li::before {
    content:"";
    width: 28px;
    height:28px;
    display:block;
    position:absolute;
    left:-1em;
    background:url($path + 'assets/svg/icon-ok.svg') no-repeat center center;
    @include mobile-xs {
      left:0em;
    }
  }
}
.e-form {
  label {
    input[type="text"],
    input[type="email"] ,
    input[type="password"],
    textarea
    {
      border-radius:16px;
      height:50px;
      line-height: 50px;
      padding:5px 32px;
      border:2px solid #CCC;
      max-width:100%;
    }
    textarea {
      min-height: (55px * 3);
    }
  }
}

.e-title {
  background-color:$branding_blue-light;
  border-radius:38px;
  padding:30px 50px;
  &--small-padding {
   padding:20px 30px;
 }
 &--extra-small-padding {
   padding:0px 20px;
   @include mobile-xs {
    padding:0px;
   }
 }
 &--no-padding {
    padding:0;
 }
}


.e-article {
  background-color:#F0F1F7;
  border-radius:24.5px;
  display: block;
  overflow:hidden;
  width:100%;
  margin:3em 0;
  @include mobile-theme {
    header {
      text-align: center;
    }
  }
  @include mobile-xs {
    header {
      text-align: left;
    }
  }
  &--first {
    margin-top:2em;
  }
  @include mobile {
    border-radius:24.5px 24.5px 0 0;
  }
  footer {
    border-radius: 0 0 24.5px 24.5px;
  }
}

.e-figure {
  display: table;
  overflow: hidden;
  border-radius:24.5px;
  padding:0;
  width:100%;
  @include mobile-theme {
    width:initial;
    margin:0 auto;
  }
  @include tablet {
  }
  @include mobile {
    border-radius:24.5px 24.5px 0 0;
  }
  img {
    display: block;
    width:100%;
    @include mobile-theme {
      width:initial;
    }
  }
}
.e-iframe {
  border-radius:24.5px;
  border:0;
  display: block;
  width:100%;
  height:350px;
  @include mobile {
    height:250px;
  }
}
.e-image-radius {
  img {
    display: block;
    border-radius:62.75px;
    @include mobile {
      border-radius:32.75px;
    }
  }
}
.e-bg-primary {
  background-color:$color_primary;
}
.e-bg-blue {
  background-color:$branding_blue;
}
.e-bg-blue-light {
  background-color:$branding_blue-light;
}
.e-bg-blue-extra-light {
  background-color:$branding_blue-extra-light;
}
.e-bg-gray-light {
  background-color:$branding_gray-light;
}
.e-bg-lilac {
  background-color:$branding_lilac;
}
.e-bg-lilac-darken {
  background-color:$branding_lilac-darken;
}
.e-bg-orange {
  background-color:$branding_orange;
}
.e-bg-salmon {
  background-color:$branding_salmon;
}
.e-bg-brown {
  background-color:$branding_brown;
}
.e-bg-green {
  background-color:$branding_green;
}
.e-bg-green-light {
  background-color:$branding_green-light;
}


.e-color-primary {
  color:$color_primary;
}
.e-color-blue {
  color:$branding_blue;
}
.e-color-blue-light {
  color:$branding_blue-light;
}
.e-color-gray-light {
  color:$branding_gray-light;
}
.e-color-lilac {
  color:$branding_lilac;
}
.e-color-lilac-darken {
  color:$branding_lilac-darken;
}
.e-color-orange {
  color:$branding_orange;
}
.e-color-salmon {
  color:$branding_salmon;
}
.e-color-brown {
  color:$branding_brown;
}
.e-color-green {
  color:$branding_green;
}




.e-color-list-primary {
  li::before {
    color:$color_primary;
  }
}
.e-color-list-blue {
  li::before {
    color:$branding_blue;
  }
}
.e-color-list-blue-light {
  li::before {
    color:$branding_blue-light;
  }
}
.e-color-list-gray-light {
  li::before {
    color:$branding_gray-light;
  }
}
.e-color-list-lilac {
  li::before {
    color:$branding_lilac;
  }
}
.e-color-list-lilac-darken {
  li::before {
    color:$branding_lilac-darken;
  }
}
.e-color-list-orange {
  li::before {
    color:$branding_orange;
  }
}
.e-color-list-salmon {
  li::before {
    color:$branding_salmon;
  }
}
.e-color-list-brown {
  li::before {
    color:$branding_brown;
  }
}
.e-color-list-green {
  li::before {
    color:$branding_green;
  }
}







@media (min-width: $mobile) {
  .bg-transparent-ns {
    background-color:transparent;
  }
}

.e-border-radius {
  &-medium {
    border-radius:38px;
  }
}
.e-table {
  border-collapse: collapse;
  tr {
    td {
      padding:8px;
      border:1px solid #EFEFEF;
    }
  }
}
// TEXT
.e-text-hypend-auto {
  -moz-hyphens:auto;
  -ms-hyphens:auto;
  -webkit-hyphens:auto;
  -o-hyphens:auto;
  hyphens:auto;
  word-wrap:break-word;
}
.e-text-line-break {
  line-break: anywhere;
}
// E-CURSOR
.e-cursor-pointer {
  cursor:pointer;
}
.e-cursor-default {
  cursor:default;
}








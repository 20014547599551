// E-MESSAGE 
.e-message {
  animation: fadeinMain-e-message 1.2s;
  text-align:center;
  padding:40px 20px;
  margin:50px 0;
  @keyframes fadeinMain-e-message {
    from { 
      opacity: 0;
      transform: translateY(50px)
    }
    to   { 
      opacity: 1;
      transform: translateY(0%)
    }
  }
  &--ok {
    background-color:#008a00;
    color:#FFF;   
  }
  &--info {
    background-color:$branding_blue-light;
    color:#FFF;
  }
  &--error {
    background-color:$branding_salmon;
    color:#FFF;
  }
}
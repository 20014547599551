.header {
  @include reset_list;
}
.header__content {

}
.header__logo {
  .item-logo {
    display:inline-block;
    width:150px;
    @media (max-width: 1300px) {
      width:125px;
    }
  }
  @media (max-width: 1300px) {
    h2 {
      font-size: 1.5em;
    }
    p {
      font-size: .9em;
    }
  }
}

.header__fixed {
  padding:5px 20px;
  @media (min-width: 1300px) {
    width:270px;
    height:100%;
    position:relative;
    &::after {
      content:"";
      position:fixed;
      top:0;
      left:268px;
      height:100%;
      width:2px;
      background-color:lighten($branding_blue,10);
    }
    &.is-fixed {
      position:fixed;
    }
  }
}
.header__menu {
  li {
    background-color:$branding_blue;
    transform: skewX(-10deg);
    &:hover ,
    &.current_page_item
    {
      background-color:lighten($branding_black,10);
    }
  }
  a {
    text-decoration: none;
    padding:16px 12px;
    display:block;
    margin:.75em 0 ;
    color:#FFF;
    text-indent: 8px;
    transform: skewX(10deg);
  }
}
@include mobile-theme {
  .item-tel-phone {
    font-size: 1em;
    text-align: right;
    img {
      width:30px;
    }
  }
  .header__logo-column {
    &--left {
      width:50%;
    }
    &--right {
      width:50%;
    }
  }
  .header {

    .item-logo {
      width:75px;
    }
    h2 {
      font-size: 1em;
    }
    p {
        display:none;
    }
  }
  .header__fixed {

  }

  .header__logo {
    display:flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  .header__content {
  }

  .header__menu {
    display:none;
  }
}
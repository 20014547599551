.flickity-page-dots .dot {
  display: inline-block;
  width: 8px;
  height: 8px;
  margin: 0 6px;
  background: #333;
  border-radius: 50%;
  opacity: 1;
  cursor: pointer;
}
.flickity-page-dots .dot
{
  background-color:$color_primary;
  opacity:.25;
}

.flickity-prev-next-button {
  svg {
    display:none;
  }

  &.previous {
    background-image:url($path + 'assets/svg/arrow-left.svg');
  }
  &.next {
    background-image:url($path + 'assets/svg/arrow-right.svg');
  }
}




    @media (min-width: $tablet_px) {
.c-slider-home {
  .flickity-page-dots {
      bottom:10px;
  }
  .flickity-button ,
  .flickity-button:hover
  {
    background:none;
    color:#FFF;
  }
  .flickity-page-dots .dot
  {
     opacity:1;
    background-color:#FFF;
  }
  .flickity-page-dots .dot:hover,
  .flickity-page-dots .dot.is-selected
  {
    background-color:$color_primary;
    opacity: 1;
  }
}
    }

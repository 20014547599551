.c-header {
  color:#FFF;
  padding:10px 10px 0px 10px;
  background-color:$branding_blue;
  display:inline-block;
  box-shadow: -3px 0px 0px #111;
  a {
    @extend .link, .dim , .white , .fw6;
  }
  .item-icon-mobile {
    svg {
      width:30px;
      path {
        fill:#FFF;
      }
    }
  }
}
.c-header__langs {
  margin:0em 0;
  @include reset_list;
  .item-nav {
    ul {
      display:flex;
      flex-wrap:wrap;
      padding-left:0;
      margin-bottom: 0;
      align-items: center;
      li {
        padding-right: .5em;
        padding-left: .5em;
        margin:.25em .25em .5em;
        display:inline-block;
        span {
          display:inline-block;
          font-size: .8em;
        }
      }
    }
  }
}
@include mobile-theme {
  .c-header {
    display:none;
  }
  @include mobile-xs {
    .c-header__langs {
      .item-nav {
        ul {
          display:flex;
          margin:0;
          padding:0;
          li {
            width:25%;
            padding:5px 5px 15px 5px;
            margin:0;
            span {
              font-size: .9em;
              & + img {
                margin:0;
                width: 25px
              }
            }
          }
        }
      }
    }
  }
}
@mixin mobile-theme {
  @media  (max-width: $grid-mobile-theme) {
    @content;
  }
} 
@mixin mobile-xs {
  @media (max-width:$mobile_px ) {
    @content;
  }
}
@mixin mobile {
	@media (max-width:$mobile ) {
		@content;
	}
}
@mixin tablet {
  @media (max-width:$tablet_px ) {
    @content;
  }
}
@mixin tablet-landscape {
  @media (max-width:$desktop_px + 1px  ) {
    @content;
  }
}
@mixin desktop {
  @media (max-width:$desktop_px) {
    @content;
  }
}

@mixin grid-container{
  width: $container;
  max-width: 100%;
  margin-right: auto;
  margin-left: auto;
  padding-right:1.5em;
  padding-left:1.5em;
  @include mobile {
    width: 100%;
  }
}

// -----------------------------------------------------------------
// Break-points
// The spec: mobile , tablet, desktop , desktop-max, full, super full
// -----------------------------------------------------------------
@mixin only_mobile {

	@media screen and (max-width:$mobile-px) {

		@content;
		
	}
}
@mixin only_tablet {

	@media screen and (max-width:$tablet-px) and (min-width:$mobile-px) {

		@content;
		
	}
}

@mixin only_desktop {

	@media screen and (max-width:$desktop-px) and (min-width:$tablet-px) {

		@content;
		
	}
}

@mixin only_desktop-max {

	@media screen and (max-width:$grid-container) and (min-width:$desktop-px) {

		@content;
		
	}
}
@mixin only_full {

	@media only screen and (min-width:$grid-container) and(max-width: $grid-super) {

		@content;
		
	}
}

@mixin only_super-full {

	@media only screen and (min-width:$grid-super) {

		@content;
		
	}
}



// -----------------------------------------------------------------
// Reset list 
// -----------------------------------------------------------------
@mixin reset_list {
	ul , li, ol , dl , dd {
		list-style: none;
    padding:0;
    margin:0;
	}
	@content;
}

// reset user agent style
@mixin reset {
  background-color: transparent;
  padding: 0;
  border: 0;
  border-radius: 0;
  color: inherit;
  line-height: inherit;
  appearance: none;
  -webkit-appearance: none;
  outline: none;
}



// CSS triangle
@mixin triangle ($direction: up, $width: 12px, $color: red) {
  width: 0;
  height: 0;
  border: $width solid transparent;

  @if( $direction == left ) {
    border-right-color: $color;
  } @else if( $direction == right ) {
    border-left-color: $color;
  } @else if( $direction == down ) {
    border-top-color: $color;
  } @else {
    border-bottom-color: $color;
  }
}

// edit font rendering -> tip: use for light text on dark backgrounds
@mixin fontSmooth {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// crop top space on text elements - caused by line height
@mixin lhCrop($line-height, $capital-letter: 1) {
  &::before {
    content: '';
    display: block;
    height: 0;
    width: 0;
    margin-top: calc((#{$capital-letter} - #{$line-height}) * 0.5em);
  }
}

.c-footer-bottom {
  background-color:#111;
  overflow: hidden;
  padding:15px;
  @include reset_list;
  text-align: center;
  a {
   color:#EFEFEF;
   @extend .dim, .link;
   border-bottom:1px dotted #CCC;
 }
 ul {
  li {
    display:inline-block;
      padding:10px;
    a {
    }
  }
 }

}
.footer {
 background-color: lighten($branding_black,10);
 color:#EFEFEF;
 min-height: 300px;
 padding:20px 30px 0 30px;
 font-size: .9em;
 @include mobile {
 padding:20px 16px 0 16px;
 }
 a {
   color:#EFEFEF;
   @extend .dim, .link;
 }
}
.footer__address {
  font-style: normal;
  display:block;
  margin-bottom: 1em;
  &::after {
    content:"";
    display:block;
    clear:both;
  }
  @include reset_list;
  h4 {
     font-size: 1.2rem;
     @include mobile {

       font-size: 1.1rem;

     }
    &::before {
      display:inline-block;
      vertical-align: middle;
      content:"";
      width:60px;
      height:60px;
      margin-right:10px;
      background:url($path + 'uploads/icono-parada-taxi.jpg') no-repeat center center / contain;
     @include mobile {
      width:40px;
      height:40px;
     }
    }
  }
  ul {
    // margin-top:1em;
  }
}
.footer__container {
}

.footer__grid {
  display:flex;
  flex-wrap:wrap;
}

.footer__column {
  border-left:20px solid $branding_blue;
  // border-radius:8px;
  flex:1;
  padding:20px;
  position:relative;
  min-width: 250px;

  @include mobile {
    border-left:10px solid $branding_blue;
  }

  &::after {
    position:absolute;
    content:"";
    left:-4px;
    top:0;
    height:100%;
    width:4px;
    border-right:2px solid #CCC;
  }

}